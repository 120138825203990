body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selected {
  box-shadow: 0px 25px 50px gray;
  transition: box-shadow 0.3s, transform 0.3s;
}

textarea {
  height: 350px;
}

.required:after {
  content: "*";
  color: #ef5f5f;
}
